import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

export const useInvalidatingMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  queryKey: QueryKey,
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
) => {
  const client = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      client.invalidateQueries({ queryKey });
      options?.onSuccess?.(...args);
    },
  });
};
