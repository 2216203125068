export const Collections = {
  tickets: 'tickets',
  ticketTypes: 'ticketTypes',
  mails: 'mails',
  mailTemplates: 'mail_templates',
};

export type Ticket = {
  id: string;
  ticketNumber: string;
  name: string;
  email: string;
  invitedBy: string;
  ticketType: string;
  price: number;
  createdAt: string;
  paidAt: string | null;
};

export type TicketType = {
  name: string;
  label: string;
  contingent: number;
  available: number;
  price: number;
};
