import { Avatar, AvatarFallback, AvatarImage } from '@packages/ui';

type Props = {
  name: string;
  avatar: string | null;
  className?: string;
};

export const UserAvatar = ({ className, name, avatar }: Props) => {
  const names = name.split(' ').filter(Boolean);
  const initials = [names[0][0], names.at(-1)?.[0] ?? ''].join('').toLocaleUpperCase();

  return (
    <Avatar className={className}>
      <AvatarImage src={avatar ?? undefined} alt={name} />
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  );
};
