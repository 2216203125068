import { useDialogStore as useDialogStore, DialogContent } from './useDialogStore';

export const useDialog = () => {
  const setOpenDialog = useDialogStore((state) => state.setOpenDialog);
  const setDialogContent = useDialogStore((state) => state.setDialogContent);
  const open = useDialogStore((state) => state.open);

  const openDialog = (dialogContent: DialogContent) => {
    setDialogContent(dialogContent);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setDialogContent(undefined);
    setOpenDialog(false);
  };

  return { openDialog, closeDialog, open };
};
