import { Input } from '@packages/ui';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { cn } from '@packages/utils';

type Props = {
  value: string | number | null | undefined;
  setValue: (value: number | null) => void;
  unit?: string | null;
  id?: string;
  allowNegative?: boolean;
  decimalScale?: number;
  allowDecimals?: boolean;
  fixedDecimalScale?: boolean;
  className?: string;
  unitClassName?: string;
  disabled?: boolean;
};

const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      value,
      setValue,
      unit,
      allowNegative,
      decimalScale = 2,
      allowDecimals = false,
      fixedDecimalScale = true,
      className,
      unitClassName,
      disabled,
    },
    ref,
  ) => {
    const separatorFormatting = { thousandSeparator: '.', decimalSeparator: ',' };

    return (
      <div className="relative">
        <NumericFormat
          id={id}
          value={value}
          onValueChange={({ floatValue }) => {
            setValue(floatValue ?? null);
          }}
          customInput={Input}
          thousandSeparator={separatorFormatting.thousandSeparator}
          decimalSeparator={separatorFormatting.decimalSeparator}
          allowNegative={allowNegative ?? false}
          getInputRef={ref}
          decimalScale={allowDecimals ? decimalScale : 0}
          allowedDecimalSeparators={allowDecimals ? undefined : []} // undefined means default separators are allowed, [] means no separators are allowed
          fixedDecimalScale={fixedDecimalScale}
          className={cn(className)}
          disabled={disabled}
        />
        {unit && (
          <div
            className={cn(
              'absolute top-0 right-0 bottom-0 flex items-center justify-center max-w-16 text-sm text-gray-400 bg-muted/30 rounded-md rounded-l-none',
              unitClassName,
            )}>
            <span className="px-3 text-muted-foreground">{unit}</span>
          </div>
        )}
      </div>
    );
  },
);

NumberInput.displayName = 'NumberInput';

export { NumberInput };
