import { App } from './App';
import { Toaster } from '@packages/ui';
import { routes } from './routes';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { UiTranslationProvider } from '@packages/ui';
import { queryClient } from './queryClient';
import { ErrorPage } from './pages/ErrorPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { getCurrentUser } from './firebase';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { ControlledDialog } from './common/components';

const router = createBrowserRouter([
  {
    element: <Login />,
    path: '/login',
  },
  {
    element: <Logout />,
    path: '/logout',
  },
  {
    element: <App />,
    errorElement: <ErrorPage />,
    loader: async () => {
      const user = await getCurrentUser();
      if (!user) {
        return redirect('/login');
      }
      const redirectTarget = localStorage.getItem('redirectTarget');
      if (redirectTarget) {
        localStorage.removeItem('redirectTarget');
        return redirect(redirectTarget);
      }

      return null;
    },
    children: [...routes, { path: '*', element: <NotFoundPage /> }],
  },
]);

export const Base = () => {
  return (
    <UiTranslationProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster /> {/* This component enables toast messages to be rendered with useToast */}
        <ControlledDialog />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </UiTranslationProvider>
  );
};
