import { ReactNode } from 'react';
import {
  TooltipProvider,
  Tooltip as TooltipBase,
  TooltipContent,
  TooltipTrigger,
} from '../../shadcn/Tooltip';

type TooltipProps = React.ComponentProps<typeof TooltipBase>;
type Props = TooltipProps & {
  children: React.ReactNode;
  content: ReactNode;
  className?: string;
};

export const Tooltip = ({ children, content, className, ...tooltipProps }: Props) => (
  <TooltipProvider>
    <TooltipBase {...tooltipProps}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className={className}>{content}</TooltipContent>
    </TooltipBase>
  </TooltipProvider>
);
