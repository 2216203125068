import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@packages/ui';
import { cn } from '@packages/utils';
import { UserAvatar } from '@/common/components';
import { Link } from 'react-router-dom';
import { Fragment, ReactNode } from 'react';
import { MenuItem } from '@/common/types';

type Props = {
  className?: string;
  user: {
    name: string;
    email: string;
    avatar: string | null;
  };
  menuItems: MenuItem[][];
  children?: ReactNode;
};

export const UserMenu = ({ user, menuItems = [], className, children }: Props) => {
  const itemGroups = menuItems.map((itemGroup, index) => {
    return (
      <Fragment key={`iG${index}`}>
        <DropdownMenuGroup>
          {itemGroup.map((item, idx) => (
            <DropdownMenuItem asChild key={`g${index}link${idx}`}>
              <Link className="!cursor-pointer" to={item.path}>
                {item.label}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
      </Fragment>
    );
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className={cn('relative h-8 w-8 rounded-full', className)}>
          <UserAvatar className="w-8 h-8 text-foreground" name={user.name} avatar={user.avatar} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[180px]" align="end" forceMount asChild>
        <nav>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">{user.name}</p>
              <p className="text-xs leading-none text-muted-foreground">{user.email}</p>
            </div>
          </DropdownMenuLabel>
          {children}
          {itemGroups}
          <DropdownMenuItem asChild>
            <Link className="!cursor-pointer" to="/logout">
              Log out
            </Link>
          </DropdownMenuItem>
        </nav>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
