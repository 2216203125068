import { ReactNode } from 'react';
import { create } from 'zustand';

export type DialogContent = {
  children: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
};

type ControlledDialogStore = {
  open: boolean;
  setOpenDialog: (open: boolean) => void;
  dialogContent: DialogContent | undefined;
  setDialogContent: (dialogContent: DialogContent | undefined) => void;
};

export const useDialogStore = create<ControlledDialogStore>((set) => ({
  open: false,
  dialogContent: undefined,
  setOpenDialog: (open) => set({ open }),
  setDialogContent: (dialogContent) => set({ dialogContent }),
}));
