import { auth } from '@/firebase';
import { signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await signOut(auth);
      navigate('/login');
    })();
  }, [navigate]);
  return null;
};
