import { toast } from '@packages/ui';
import { MutationCache, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      toast({
        title: 'Error',
        variant: 'destructive',
        duration: 3000,
        description: error instanceof Error ? error.message : 'Something went wrong.',
      });
    },
  }),
  defaultOptions: { queries: { retry: false, staleTime: 60 * 1000 } },
});
