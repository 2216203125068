import { ticketQuery, ticketTypeQuery } from '@/api/queries';
import { Card } from '@packages/ui';
import { useQuery } from '@tanstack/react-query';
import * as _ from 'lodash-es';

export const Dashboard = () => {
  const { data: tickets } = useQuery(ticketQuery());
  const { data: ticketTypes } = useQuery(ticketTypeQuery());

  if (!tickets || !ticketTypes) {
    return null;
  }

  const totalTicketsCount = tickets.length;
  const paidTickets = tickets.filter((t) => t.paidAt);
  const totalPaidTicketsCount = paidTickets.length;

  const totalAmount = _.sumBy(tickets, 'price');
  const totalPaidAmount = _.sumBy(paidTickets, 'price');

  return (
    <>
      <h1 className="font-bold text-lg mt-4 mb-8">Dashboard</h1>
      <h1 className="text-xl ml-3 mb-1">Total</h1>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        <Card className="p-4">
          <h1 className="text-xl">Tickets</h1>
          <h2 className="text-5xl font-bold">{totalTicketsCount}</h2>
        </Card>
        <Card className="p-4">
          <h1 className="text-xl">Amount</h1>
          <h2 className="text-5xl font-bold">{totalAmount} EUR</h2>
        </Card>
      </div>

      <h1 className="mt-8 text-xl ml-3 mb-1 text-success">Paid</h1>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        <Card className="p-4">
          <h1 className="text-xl">Tickets</h1>
          <h2 className="text-5xl font-bold text-success">{totalPaidTicketsCount}</h2>
        </Card>
        <Card className="p-4">
          <h1 className="text-xl">Amount</h1>
          <h2 className="text-5xl font-bold text-success">{totalPaidAmount} EUR</h2>
        </Card>
      </div>

      <h1 className="mt-8 text-xl ml-3 mb-1 text-warning">Open (not yet paid)</h1>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        <Card className="p-4">
          <h1 className="text-xl">Tickets</h1>
          <h2 className="text-5xl font-bold text-warning">
            {totalTicketsCount - totalPaidTicketsCount}
          </h2>
        </Card>
        <Card className="p-4">
          <h1 className="text-xl">Amount</h1>
          <h2 className="text-5xl font-bold text-warning">{totalAmount - totalPaidAmount} EUR</h2>
        </Card>
      </div>
    </>
  );
};
