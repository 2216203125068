import {
  ColumnDef,
  ColumnPinningState,
  OnChangeFn,
  PaginationState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Checkbox,
  DataTablePaginationOptions,
  DataTableViewOptions,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  UiTranslationContext,
} from '@packages/ui';

import { DataTablePagination } from './DataTablePagination';
import { cn } from '@packages/utils';
import { LineLoader } from '../LineLoader';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ReactNode, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import './DataTable.css';
import { useScroll } from '@packages/utils';

export interface DataTableProps<TData> {
  id: string;
  columns: ColumnDef<TData, any>[];
  data: TData[];
  pagination?: DataTablePaginationOptions;
  setPagination?: OnChangeFn<PaginationState>;
  isLoadingData?: boolean;
  hasLoadedData?: boolean;
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState>;
  children?: ReactNode;
  hideViewOptions?: boolean;
  isSelected?: (item: TData) => boolean;
  toggleIsSelected?: (item: TData) => void;
  className?: string;
}

export function DataTable<TData>({
  id,
  columns,
  data,
  pagination,
  setPagination,
  isLoadingData,
  hasLoadedData,
  sorting,
  setSorting,
  children,
  hideViewOptions,
  isSelected,
  toggleIsSelected,
  className,
}: DataTableProps<TData>) {
  const translations = useContext(UiTranslationContext);
  const [columnVisibility, setColumnVisibility] = useLocalStorage<VisibilityState>(
    `${id}_visibility`,
    {},
  );

  const isSelectable = useMemo(
    () => !!(isSelected ?? toggleIsSelected),
    [isSelected, toggleIsSelected],
  );

  const selectableColumn: ColumnDef<TData, any>[] = useMemo(
    () =>
      isSelectable
        ? [
            {
              id: 'selection',
              meta: {
                cellClassName: 'min-w-[60px]',
                pin: 'left',
              },
              cell: ({ row }) => (
                <Checkbox
                  onCheckedChange={
                    toggleIsSelected ? () => toggleIsSelected(row.original) : undefined
                  }
                  checked={isSelected ? (() => isSelected(row.original))() : undefined}
                />
              ),
            },
          ]
        : [],
    [isSelectable, toggleIsSelected, isSelected],
  );

  const mutatedColumns: ColumnDef<TData, any>[] = useMemo(
    () => [...selectableColumn, ...columns],
    [selectableColumn, columns],
  );

  const paginationState: PaginationState | undefined = pagination && {
    pageIndex: pagination.currentPage - 1,
    pageSize: pagination.perPage,
  };

  const columnPinningState = useMemo<ColumnPinningState>(() => {
    const pinnedColumns = mutatedColumns.filter((col) => col.meta?.pin);
    return {
      left: pinnedColumns
        .filter((col) => col.meta?.pin === 'left' && col.id && columnVisibility[col.id] !== false)
        .map((col) => col.id) as string[],
      right: pinnedColumns
        .filter((col) => col.meta?.pin === 'right' && col.id && columnVisibility[col.id] !== false)
        .map((col) => col.id) as string[],
    };
  }, [mutatedColumns, columnVisibility]);

  const table = useReactTable({
    data,
    columns: mutatedColumns,
    state: {
      sorting,
      pagination: paginationState,
      columnVisibility,
      columnPinning: columnPinningState,
    },
    pageCount: pagination?.totalPages,
    enableSorting: !!setSorting,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    enableFilters: false,
    manualSorting: true,
    manualPagination: true,
    enablePinning: true,
    enableColumnPinning: true,
    enableRowPinning: false,
    debugTable: false,
  });

  useLayoutEffect(() => {
    let left = 0;
    columnPinningState.left?.forEach((columnId) => {
      // NodeList of every cell in a column
      const query: NodeListOf<HTMLElement> | undefined = document.querySelectorAll(
        `[data-id="${columnId}"]`,
      );
      if (!query) return;
      const columnCells: HTMLElement[] = [...query];
      const th = columnCells[0];
      if (columnCells.length && th) {
        columnCells.forEach((cell) => {
          cell.style.left = `${left}px`;
        });

        // before we go to the next column, we take the width of this column and add it to our offset
        left += th.getBoundingClientRect().width;
      }
    });

    let right = 0;
    columnPinningState.right?.reverse().forEach((columnId) => {
      // NodeList of every cell in a column
      const query: NodeListOf<HTMLElement> | undefined = document.querySelectorAll(
        `[data-id="${columnId}"]`,
      );
      if (!query) return;
      const columnCells: HTMLElement[] = [...query];
      const th = columnCells[0];
      if (columnCells.length && th) {
        columnCells.forEach((cell) => {
          cell.style.right = `${right}px`;
        });

        right += th.getBoundingClientRect().width;
      }
    });
  }, [columnPinningState, data]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const { horizontalScrollPosition } = useScroll(scrollAreaRef);

  const isLastLeftPinnedColumn = (id: string) => (columnPinningState?.left ?? []).at(-1) === id;
  const isFirstRightPinnedColumn = (id: string) => (columnPinningState?.right ?? []).at(0) === id;

  return (
    <div className={cn('space-y-4', className)}>
      {(!!children || !hideViewOptions) && (
        <div className="flex justify-between gap-2">
          {children}
          {!hideViewOptions ? <DataTableViewOptions table={table} /> : null}
        </div>
      )}
      <div className="rounded-md border relative flex w-full shadow">
        {isLoadingData ? <LineLoader className="rounded-t absolute opacity-50 h-1 z-[2]" /> : null}
        <div className="w-0 grow rounded-md overflow-hidden">
          <ScrollArea
            ref={scrollAreaRef}
            scrollbarClassName="z-[2]"
            scrollbarClassNameHorizontal="z-[2]">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow className="bg-table-header" key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          data-id={header.column.id}
                          className={cn(
                            'bg-table-header',
                            header.column.getIsPinned() && 'bg-clip-padding sticky z-[1]',
                            isLastLeftPinnedColumn(header.column.id) &&
                              (horizontalScrollPosition === 'center' ||
                                horizontalScrollPosition === 'right') &&
                              'shadow-right',
                            isFirstRightPinnedColumn(header.column.id) &&
                              (horizontalScrollPosition === 'center' ||
                                horizontalScrollPosition === 'left') &&
                              'shadow-left',
                          )}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      className="bg-table"
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          data-id={cell.column.id}
                          className={cn(
                            'bg-background',
                            cell.column.columnDef.meta?.cellClassName,
                            cell.column.getIsPinned() && 'bg-clip-padding sticky z-[1]',
                            isLastLeftPinnedColumn(cell.column.id) &&
                              (horizontalScrollPosition === 'center' ||
                                horizontalScrollPosition === 'right') &&
                              'shadow-right',
                            isFirstRightPinnedColumn(cell.column.id) &&
                              (horizontalScrollPosition === 'center' ||
                                horizontalScrollPosition === 'left') &&
                              'shadow-left',
                          )}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      {hasLoadedData ? translations.table.empty : translations.table.loading}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ScrollArea>
        </div>
      </div>
      {setPagination && <DataTablePagination table={table} disabled={isLoadingData} />}
    </div>
  );
}
