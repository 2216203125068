import { RouteObject } from 'react-router-dom';
import { Dashboard } from './pages/Dashboard';
import { TicketTypes } from './pages/TicketTypes';
import { ticketsFeature } from './features/tickets/ticketsFeature';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/ticket-types',
    element: <TicketTypes />,
  },
  ticketsFeature,
];
