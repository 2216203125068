import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps } from 'class-variance-authority';
import { buttonVariants } from './buttonVariants';

import { cn } from '@packages/utils';
import { ReloadIcon } from '@radix-ui/react-icons';
import { useState } from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  asChild?: boolean;
  contentClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      disabled = false,
      asChild = false,
      onClick,
      children,
      contentClassName,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const [loading, setIsLoading] = useState(false);

    const handleClick = onClick
      ? async (event: React.MouseEvent<HTMLButtonElement>) => {
          setIsLoading(true);
          try {
            await onClick(event);
          } finally {
            setIsLoading(false);
          }
        }
      : undefined;

    return (
      <Comp
        disabled={disabled || loading}
        onClick={handleClick}
        className={cn(buttonVariants({ variant, size, className }), 'relative')}
        ref={ref}
        type={type}
        {...props}>
        {loading && <ReloadIcon className={cn('animate-spin absolute')} />}
        <span
          className={cn(
            'inline-flex items-center justify-center',
            loading && 'opacity-0',
            contentClassName,
          )}>
          {children}
        </span>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button };
