import { useMemo } from 'react';
import { format, formatDistance as formatDistanceFn } from 'date-fns';

export const useDate = () => {
  const formatDate = (date: Date) => {
    return format(date, 'dd.MM.yyyy');
  };
  const formatDateTime = (date: Date, includeSeconds = false) => {
    return format(date, `dd.MM.yyyy HH:mm${includeSeconds ? ':ss' : ''}`);
  };

  const formatDistance = (date: Date, date2: Date) => {
    return formatDistanceFn(date, date2, { addSuffix: true });
  };

  const today = useMemo(() => new Date(), []);

  const monthsToDays = (months: number) => Math.round(months * 30);

  const daysToMonths = (days: number) => Math.round(days / 30);

  return { formatDate, formatDateTime, formatDistance, today, monthsToDays, daysToMonths };
};
