import { AddTicketData, FirebaseClient } from '@/api/firebaseClient';
import { QUERY_KEYS, ticketQuery, ticketTypeQuery } from '@/api/queries';
import { DateTime, useDialog } from '@/common/components';
import { useInvalidatingMutation } from '@/hooks/useInvalidatingMutation';
import { Ticket, TicketType } from '@packages/types';
import { Button, DataTable, DataTableColumnHeader, Input, Tooltip } from '@packages/ui';
import { useQuery } from '@tanstack/react-query';
import { ColumnSort, createColumnHelper } from '@tanstack/react-table';
import { DollarSign, Undo } from 'lucide-react';
import { useMemo, useState } from 'react';
import { TicketForm, TicketFormFields } from './components/TicketForm';

const c = createColumnHelper<Omit<Ticket, 'ticketType'> & { ticketType?: TicketType }>();
const columns = [
  c.accessor((row) => row.ticketNumber, {
    id: 'ticketNumber',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      pin: 'left',
      headerTitle: () => 'Ticket number',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  c.accessor((row) => row.name, {
    id: 'name',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Name',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  c.accessor((row) => row.email, {
    id: 'email',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Email',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      const email = getValue();
      return <a href={`mailto:${email}`}>{email}</a>;
    },
  }),
  c.accessor((row) => row.invitedBy, {
    id: 'invitedBy',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Invited by',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  c.accessor((row) => row.ticketType, {
    id: 'type',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Type',
      cellClassName: 'min-w-[80px]',
    },
    cell: ({ getValue }) => {
      return getValue()?.label ?? '-';
    },
  }),
  c.accessor((row) => row.price, {
    id: 'price',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Price',
      cellClassName: 'min-w-[80px]',
    },
    cell: ({ getValue }) => {
      return `${getValue() ?? '-'} EUR`;
    },
  }),
  c.accessor((row) => row.createdAt, {
    id: 'boughtAt',
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Bought at',
      cellClassName: 'min-w-[120px]',
    },
    enableSorting: true,
    cell: ({ getValue }) => {
      return <DateTime value={getValue()} />;
    },
  }),
  c.accessor((row) => row.paidAt, {
    id: 'paidAt',
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Paid at',
      cellClassName: 'min-w-[120px]',
    },
    enableSorting: true,
    cell: ({ getValue }) => {
      const paidAt = getValue();

      return <DateTime value={paidAt} />;
    },
  }),
];

export const TicketsFeaturePage = () => {
  const client = new FirebaseClient();
  const { data: ticketTypes } = useQuery(ticketTypeQuery());
  const { data: ticketsRaw } = useQuery(ticketQuery());
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'boughtAt', desc: true }]);

  const { openDialog, closeDialog } = useDialog();

  const availableTicketTypes = useMemo(
    () => (ticketTypes ?? []).filter((t) => t.available > 0).sort((a, b) => a.price - b.price),
    [ticketTypes],
  );

  const [filter, setFilter] = useState('');
  const count = useMemo(() => ticketsRaw?.length ?? 0, [ticketsRaw]);

  const tickets = useMemo(() => {
    return (ticketsRaw ?? [])
      .filter((ticket) => {
        const f = filter.toLocaleLowerCase();
        return (
          ticket.name.toLowerCase().includes(f) ||
          ticket.email.toLowerCase().includes(f) ||
          ticket.ticketNumber.toLowerCase().includes(f)
        );
      })
      .map((t) => {
        return { ...t, ticketType: ticketTypes?.find((type) => type.name === t.ticketType) };
      })
      .sort((a, b) => {
        let n1: number, n2: number;
        if (sorting[0].id === 'paidAt') {
          n1 = a.paidAt ? new Date(a.paidAt).getTime() : 0;
          n2 = b.paidAt ? new Date(b.paidAt).getTime() : 0;
        } else {
          n1 = new Date(a.createdAt).getTime();
          n2 = new Date(b.createdAt).getTime();
        }
        if (sorting[0].desc) {
          return n2 - n1;
        }
        return n1 - n2;
      });
  }, [ticketsRaw, filter, sorting, ticketTypes]);

  const { mutateAsync: markAsPaid } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (id: string) => client.markAsPaid(id),
  });
  const { mutateAsync: undoPayment } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (id: string) => client.undoPayment(id),
  });
  const { mutateAsync: addTicket } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (data: AddTicketData) => client.addTicket(data),
  });

  const columnsWithActions = [
    ...columns,
    c.display({
      id: 'actions',
      cell: ({ row }) => {
        if (!row.original.paidAt) {
          return (
            <Button onClick={() => markAsPaid(row.original.id)} size={'icon-sm'}>
              <Tooltip content="Mark as paid" delayDuration={0}>
                <DollarSign size={14} />
              </Tooltip>
            </Button>
          );
        } else {
          return (
            <Button onClick={() => undoPayment(row.original.id)} size={'icon-sm'}>
              <Tooltip content="Undo payment" delayDuration={0}>
                <Undo size={14} />
              </Tooltip>
            </Button>
          );
        }
      },
    }),
  ];

  const onCreateTicket = async (data: TicketFormFields) => {
    await addTicket(data);
    closeDialog();
  };

  return (
    <>
      <h1 className="font-bold text-lg mt-4">Tickets ({count})</h1>
      <div className="my-2 flex justify-between">
        <Input
          className="h-9 text-sm"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Filter tickets"
        />
        <Button
          onClick={() =>
            openDialog({
              children: <TicketForm onSubmit={onCreateTicket} ticketTypes={availableTicketTypes} />,
              description:
                'Add a new ticket holder. If you mark it as paid, they will get a confirmation email about their payment.',
              title: 'Add Ticket',
            })
          }
          size="sm">
          Add Ticket
        </Button>
      </div>

      <DataTable
        id="tickets"
        columns={columnsWithActions}
        data={tickets}
        hideViewOptions
        hasLoadedData
        sorting={sorting}
        setSorting={setSorting}
      />
    </>
  );
};
