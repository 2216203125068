import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@packages/ui';
import { NavLink } from 'react-router-dom';
import { MenuItem } from '@/common/types';

type Props = {
  menuItems: MenuItem[];
  className?: string;
};

export const MainNav = ({ menuItems, className }: Props) => {
  return (
    <NavigationMenu className={className}>
      <NavigationMenuList>
        {menuItems.map((item) => (
          <NavigationMenuItem key={`menuItem${item.path}`}>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle({ variant: 'mainNav' })}
              asChild>
              <NavLink to={item.path}>{item.label}</NavLink>
            </NavigationMenuLink>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};
