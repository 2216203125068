import { RefObject, useEffect, useState } from 'react';

export const VIEW_AREA_SELECTOR = '[data-radix-scroll-area-viewport]';

// none if element is not scrollable
type HorizontalScrollPosition = 'left' | 'center' | 'right' | 'none';
type VerticalScrollPosition = 'top' | 'center' | 'bottom' | 'none';

const getVerticalScrollPosition = (el: Element): VerticalScrollPosition => {
  if (el.clientHeight === el.scrollHeight) return 'none';
  if (el.scrollTop === 0) {
    return 'top';
  }
  if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
    return 'bottom';
  }
  return 'center';
};

const getHorizontalScrollPosition = (el: Element): HorizontalScrollPosition => {
  if (el.clientWidth === el.scrollWidth) return 'none';
  if (el.scrollLeft === 0) {
    return 'left';
  }
  if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
    return 'right';
  }
  return 'center';
};

/**
 * Use this hook to get scrolling data of the given element or, if non is given, the top level scrollable element
 */
export const useScroll = (el?: RefObject<HTMLElement>) => {
  const [verticalScrollPosition, setVerticalScrollPosition] =
    useState<VerticalScrollPosition>('none');
  const [horizontalScrollPosition, setHorizontalScrollPosition] =
    useState<HorizontalScrollPosition>('none');

  useEffect(() => {
    const scrollableElement = el?.current
      ? el.current.querySelector(VIEW_AREA_SELECTOR)
      : document.querySelector(VIEW_AREA_SELECTOR);

    const observer = new ResizeObserver(() => {
      scrollCallback();
    });

    const scrollCallback = () => {
      if (!scrollableElement) {
        return;
      }

      setVerticalScrollPosition(getVerticalScrollPosition(scrollableElement));
      setHorizontalScrollPosition(getHorizontalScrollPosition(scrollableElement));
    };

    scrollableElement?.addEventListener('scroll', scrollCallback);
    if (scrollableElement) {
      observer.observe(scrollableElement);
    }
    return () => {
      scrollableElement?.removeEventListener('scroll', scrollCallback);
      observer.disconnect();
    };
  }, [el]);

  return {
    verticalScrollPosition,
    horizontalScrollPosition,
  };
};
