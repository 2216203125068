import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@packages/ui';
import { useDialogStore } from './useDialogStore';

export const ControlledDialog = () => {
  const dialogContent = useDialogStore((state) => state.dialogContent);
  const open = useDialogStore((state) => state.open);
  const setOpenDialog = useDialogStore((state) => state.setOpenDialog);

  return (
    <Dialog open={open} onOpenChange={() => setOpenDialog(!open)}>
      <DialogContent onInteractOutside={(e) => e.preventDefault()}>
        {dialogContent?.title ?? dialogContent?.description ? (
          <DialogHeader>
            <DialogTitle>{dialogContent?.title}</DialogTitle>
            <DialogDescription>{dialogContent?.description}</DialogDescription>
          </DialogHeader>
        ) : null}

        {dialogContent?.children}
      </DialogContent>
    </Dialog>
  );
};
