import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import { Base } from './Base.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Base />
  </React.StrictMode>,
);
