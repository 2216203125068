import { ticketTypeQuery } from '@/api/queries';
import { TicketType } from '@packages/types';
import { DataTable, DataTableColumnHeader } from '@packages/ui';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

const c = createColumnHelper<TicketType>();
const columns = [
  c.accessor((row) => row.label, {
    id: 'name',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      pin: 'left',
      headerTitle: () => 'Name',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  c.accessor((row) => row.price, {
    id: 'price',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Price',
    },
    cell: ({ getValue }) => {
      return `${getValue() ?? '-'} EUR`;
    },
  }),
  c.accessor((row) => row.contingent, {
    id: 'contingent',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Contingent',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  c.accessor((row) => row.available, {
    id: 'available',
    enableSorting: false,
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    meta: {
      headerTitle: () => 'Available',
      cellClassName: 'min-w-[120px]',
    },
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
];

export const TicketTypes = () => {
  const { data: ticketTypes } = useQuery(ticketTypeQuery());

  const sortedTicketTypes = useMemo(
    () => (ticketTypes ?? []).slice().sort((a, b) => a.price - b.price),
    [ticketTypes],
  );

  return (
    <>
      <h1 className="font-bold text-lg mt-4 mb-4">Ticket Types</h1>

      <DataTable id="ticketTypes" columns={columns} data={sortedTicketTypes} hideViewOptions />
    </>
  );
};
