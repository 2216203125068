import { ReactNode, createContext } from 'react';

export type UiTranslations = {
  table: {
    loading: ReactNode;
    empty: ReactNode;
    viewOptions: ReactNode;
  };
  pagination: {
    rowsPerPage: ReactNode;
    pageOf: (currentPage: number, totalPages: number) => ReactNode;
    goToFirstPage: ReactNode;
    goToPreviousPage: ReactNode;
    goToNextPage: ReactNode;
    goToLastPage: ReactNode;
  };
  sorting: {
    asc: ReactNode;
    desc: ReactNode;
  };
  generic: {
    noResults: ReactNode;
    hide: ReactNode;
  };
  select: {
    clearSelection: ReactNode;
  };
};

const defaultValues: UiTranslations = {
  table: {
    loading: 'Loading data...',
    empty: 'No results',
    viewOptions: 'View',
  },
  pagination: {
    rowsPerPage: 'Rows per page',
    pageOf: (currentPage: number, totalPages: number) => `Row ${currentPage} of ${totalPages}`,
    goToFirstPage: 'Go to first page',
    goToPreviousPage: 'Go to previous page',
    goToNextPage: 'Go to next page',
    goToLastPage: 'Go to last page',
  },
  sorting: {
    asc: 'Asc',
    desc: 'Desc',
  },
  generic: {
    noResults: 'No results',
    hide: 'Hide',
  },
  select: {
    clearSelection: 'Clear selection',
  },
};

export const UiTranslationContext = createContext<UiTranslations>(defaultValues);

type Props = {
  children: ReactNode;
  translations?: UiTranslations;
};
export const UiTranslationProvider = ({ children, translations = defaultValues }: Props) => {
  return (
    <UiTranslationContext.Provider value={translations}>{children}</UiTranslationContext.Provider>
  );
};
