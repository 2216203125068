import { Logo } from '@/common/components';
import { MenuItem } from '@/common/types';
import { Button, ScrollArea, Sheet, SheetContent, SheetTrigger } from '@packages/ui';
import { cn } from '@packages/utils';
import { Menu } from 'lucide-react';
import { useState } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';

type Props = {
  menuItems: MenuItem[];
  className?: string;
  size?: number;
};
export function MobileNav({ menuItems, className, size = 25 }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            'px-2 -ml-2 text-base focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0',
            className,
          )}>
          <Menu size={size} />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="pr-20">
        <MobileLink to="/" className="flex items-center" onOpenChange={setOpen}>
          <Logo className="mr-2 h-8" />
        </MobileLink>
        <ScrollArea className="my-8 h-[calc(100vh-8rem)] pb-10">
          <div className="flex flex-col space-y-3">
            {menuItems.map((item) => (
              <MobileLink key={item.path} to={item.path} onOpenChange={setOpen}>
                {item.label}
              </MobileLink>
            ))}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}

interface MobileLinkProps extends LinkProps {
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

function MobileLink({ to, onOpenChange, className, children, ...props }: MobileLinkProps) {
  const navigate = useNavigate();
  return (
    <Link
      to={to}
      onClick={() => {
        navigate(to);
        onOpenChange?.(false);
      }}
      className={cn(className)}
      {...props}>
      {children}
    </Link>
  );
}
