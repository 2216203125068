import background from '@/assets/bg.jpeg';
import { Button, Input, ScrollArea } from '@packages/ui';
import { auth } from '@/firebase';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';

export const Login = () => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const signIn = async () => {
    if (!email) {
      setError('Please enter an email.');
      return;
    }
    if (!password) {
      setError('Please enter a password.');
      return;
    }
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (result.user) {
        navigate('/');
      }
    } catch (e) {
      if (e instanceof FirebaseError) {
        switch (e.code) {
          case 'auth/auth/invalid-email':
            setError('Invalid Email');
            break;
          case 'auth/wrong-password':
            setError('Invalid password.');
            break;
          default:
            setError('Could not sign in.');
            break;
        }
      } else {
        setError('Could not sign in.');
      }
    }
  };

  return (
    <div
      className="h-screen w-screen flex flex-col overflow-hidden"
      style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <main className="grow min-h-0 container flex justify-center mt-12 mb-12 overflow-hidden">
        <ScrollArea className="border border-white rounded-xl space-y-4 flex flex-col text-white backdrop-blur px-4 pb-4 self-start max-h-full overflow-scroll">
          <form className="my-2 space-y-4">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button className="w-full" onClick={signIn}>
              Sign in
            </Button>
            {error ? <p className="text-red-500 mt-4">{error}</p> : null}
          </form>
        </ScrollArea>
      </main>
    </div>
  );
};
