import { AppLayout } from './layout/AppLayout';
import { Outlet, useNavigation } from 'react-router-dom';
import { MenuItem } from '@/common/types';

export const App = () => {
  const menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      path: '/',
    },
    {
      label: 'Tickets',
      path: '/tickets',
    },
    {
      label: 'Ticket Types',
      path: '/ticket-types',
    },
  ];

  const userMenuItems = [[]];

  const { state } = useNavigation();
  const isLoading = state === 'loading';

  return (
    <>
      <AppLayout menuItems={menuItems} userMenuItems={userMenuItems} isLoading={isLoading}>
        <Outlet />
      </AppLayout>
    </>
  );
};
