import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Switch,
  NumberInput,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@packages/ui';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import zod from 'zod';
import { TicketType } from '@packages/types';

const formSchema = zod.object({
  name: zod.string().min(1),
  email: zod.string().email(),
  ticketType: zod.string(),
  price: zod.number(),
  isPaid: zod.boolean(),
});

export type TicketFormFields = zod.infer<typeof formSchema>;

type Props = {
  onSubmit: (formFields: TicketFormFields) => Promise<void>;
  ticketTypes: TicketType[];
};

export const TicketForm = ({ onSubmit, ticketTypes }: Props) => {
  const invalidHandler = (error: any) => {
    console.error(error);
  };

  const form = useForm<TicketFormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      isPaid: true,
      name: '',
      price: ticketTypes[0].price,
      ticketType: ticketTypes[0].name,
    },
  });

  const onSelectTicketType = (ticketType: string | undefined) => {
    const selectedTicketType = ticketTypes.find((t) => t.name === ticketType);
    if (selectedTicketType) {
      form.setValue('price', selectedTicketType.price);
      form.setValue('ticketType', selectedTicketType.name);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={(event) => void form.handleSubmit(onSubmit, invalidHandler)(event)}>
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Name" className="w-full" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" className="w-full" type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="ticketType"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Select
                      value={field.value}
                      onValueChange={(value: string) => {
                        onSelectTicketType(value);
                      }}>
                      <SelectTrigger className="w-full bg-white/10">
                        <SelectValue placeholder={field.value} />
                      </SelectTrigger>
                      <SelectContent className="">
                        {ticketTypes.map((type) => (
                          <SelectItem key={type.name} value={type.name}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="price"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Price</FormLabel>
                  <FormControl>
                    <NumberInput
                      {...field}
                      className="w-full"
                      value={field.value}
                      unit="EUR"
                      setValue={(value) => field.onChange(value ?? undefined)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="isPaid"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <div className="flex items-center space-x-4">
                      <Switch onCheckedChange={field.onChange} checked={field.value} />
                      <FormLabel>Has paid</FormLabel>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>

        <div>
          <Button className="mt-10" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};
