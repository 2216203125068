import { cva } from 'class-variance-authority';

export const navigationMenuTriggerStyle = cva(
  'group inline-flex w-max items-center justify-center px-4 py-2 text-sm transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 hover:bg-accent hover:text-accent-foreground font-medium focus:bg-accent focus:text-accent-foreground',
        mainNav:
          'aria-[current="page"]:bg-foreground/5 aria-[current="page"]:text-primary rounded-md text-foreground hover:bg-foreground/5 font-medium hover:text-foreground',
        tabs: 'aria-[current="page"]:bg-background aria-[current="page"]:text-primary aria-[current="page"]:shadow font-medium !py-1 rounded-md hover:text-foreground',
      },
      disabled: {
        true: 'pointer-events-none opacity-50',
        false: 'opacity-100',
      },
    },
    defaultVariants: {
      variant: 'default',
      disabled: false,
    },
  },
);
