import React from 'react';
import { UserMenu } from './UserMenu';
import { MainNav } from './MainNav';
import { MenuItem } from '@/common/types';
import { MobileNav } from './MobileNav';

interface Props {
  children: React.ReactNode;
  menuItems: MenuItem[];
  userMenuItems: MenuItem[][];
  isLoading?: boolean;
  headerRight?: React.ReactNode;
  customUserMenu?: React.ReactNode;
}

const HEADER_HEIGHT_PX = 56;

export const AppLayout = ({
  children,
  menuItems,
  userMenuItems,
  headerRight,
  customUserMenu,
}: Props) => {
  const userData = {
    name: 'Family Affairs',
    email: 'festival@familyaffairs.info',
    avatar: null,
  };

  return (
    <div className="h-screen w-screen relative">
      <header className="border-b text-foreground fixed w-full top-0 z-[2] bg-background/40">
        <div className="backdrop-blur" style={{ background: 'var(--header)' }}>
          <div className="container flex items-center" style={{ height: `${HEADER_HEIGHT_PX}px` }}>
            <MainNav menuItems={menuItems} className="hidden md:flex" />

            <div className="ml-auto flex items-center">
              {headerRight}
              <UserMenu className="ml-auto" user={userData} menuItems={userMenuItems}>
                {customUserMenu}
              </UserMenu>
              <MobileNav menuItems={menuItems} className="md:hidden ml-2" />
            </div>
          </div>
        </div>
      </header>
      <main className="h-full w-full relative pt-14 container">{children}</main>
    </div>
  );
};
