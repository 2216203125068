import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cn } from '@packages/utils';

type Props = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  progressBarClassName?: string;
  labelClassName?: string;
  showLabel?: boolean;
};

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, Props>(
  (
    {
      className,
      value,
      progressBarClassName,
      showLabel = false,
      labelClassName = 'text-xs text-[#ffffff]',
      ...props
    },
    ref,
  ) => (
    <ProgressPrimitive.Root
      value={value}
      ref={ref}
      className={cn('relative h-2 w-full overflow-hidden rounded-full bg-primary/20', className)}
      {...props}>
      <ProgressPrimitive.Indicator
        className={cn(
          'h-full w-full text-right flex-1 items-center text-xs bg-primary transition-all text-background',
          progressBarClassName,
        )}
        style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }}
      />
      {showLabel ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className={labelClassName}>{value}%</span>
        </div>
      ) : null}
    </ProgressPrimitive.Root>
  ),
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
