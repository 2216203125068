import { useDate } from '@/common/hooks/useDate';

type Props = {
  value: string | Date | null | undefined;
};

export const DateTime = ({ value }: Props) => {
  const { formatDateTime } = useDate();
  if (!value) return '-';

  return <span>{formatDateTime(new Date(value))}</span>;
};
