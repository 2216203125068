import { cn } from '@packages/utils';
import './lineLoader.css';

type Props = {
  className?: string;
};
export const LineLoader = ({ className }: Props) => {
  return (
    <div
      role="progressbar"
      aria-label="Loading"
      className={cn('line-loader w-full h-1.5 relative overflow-hidden bg-primary/20', className)}
    />
  );
};
