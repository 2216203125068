import {
  CollectionReference,
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  setDoc,
  addDoc,
  getDoc,
  where,
} from 'firebase/firestore';
import { Collections, Mails, Ticket, TicketType } from '@packages/types';
import { firestore } from '@/firebase';

export type AddTicketData = {
  name: string;
  email: string;
  ticketType: string;
  price: number;
  isPaid: boolean;
};

const createTicketNumber = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ23456789';
  return Array.from({ length })
    .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
    .join('');
};

export class FirebaseClient {
  private ticketTypeCollection: CollectionReference<TicketType, TicketType>;
  private ticketCollection: CollectionReference<Ticket, Ticket>;
  private mailCollection: CollectionReference<Mails, Mails>;

  constructor() {
    this.ticketTypeCollection = collection(
      firestore,
      Collections.ticketTypes,
    ) as CollectionReference<TicketType, TicketType>;
    this.ticketCollection = collection(firestore, Collections.tickets) as CollectionReference<
      Ticket,
      Ticket
    >;
    this.mailCollection = collection(firestore, Collections.mails) as CollectionReference<
      Mails,
      Mails
    >;
  }

  async getTicketTypes(): Promise<TicketType[]> {
    const ticketTypeDocs = await getDocs(query(this.ticketTypeCollection));
    return ticketTypeDocs.docs.map((doc) => doc.data());
  }

  async getTickets(): Promise<Ticket[]> {
    const ticketDocs = await getDocs(query(this.ticketCollection));
    return ticketDocs.docs.map((doc) => doc.data());
  }

  async addTicket(data: AddTicketData): Promise<void> {
    const ticketTypes = await getDocs(
      query(this.ticketTypeCollection, where('name', '==', data.ticketType)),
    );
    const ticketTypeRef = ticketTypes.docs[0];
    if (!ticketTypeRef.exists) {
      throw new Error('Ticket type does not exist');
    }
    const ticketTypeData = ticketTypeRef.data();
    if (ticketTypeData.available === 0) {
      throw new Error('Ticket type is sold out');
    }

    const id = createTicketNumber(12);
    const ticketNumber = `WA-${id.substring(0, 5)}`;

    await setDoc(doc(this.ticketCollection, id), {
      createdAt: new Date().toISOString(),
      email: data.email,
      id,
      name: data.name,
      paidAt: null,
      price: data.price,
      ticketNumber,
      ticketType: data.ticketType,
    });
    await updateDoc(ticketTypeRef.ref, { available: ticketTypeData.available - 1 });

    if (data.isPaid) {
      await this.markAsPaid(id);
    }
  }

  async markAsPaid(id: string) {
    const docRef = doc(this.ticketCollection, id);
    const ticket = (await getDoc(docRef)).data();

    if (!ticket) throw new Error('Ticket does not exist');

    await updateDoc(docRef, {
      paidAt: new Date().toISOString(),
    });

    await addDoc(this.mailCollection, {
      template: {
        name: 'ticket_payment_received',
        data: {
          name: ticket.name,
          ticketURL: `https://familyaffairs.info/tickets/${ticket.id}`,
        },
      },
      to: [ticket.email],
      from: 'Family Affairs <festival@familyaffairs.info>',
    });
  }

  async undoPayment(id: string) {
    const docRef = doc(this.ticketCollection, id);
    await updateDoc(docRef, {
      paidAt: null,
    });
  }
}
